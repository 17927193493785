const firebaseConfig = {
  apiKey: 'AIzaSyClMTsVciVXgkZ_Nbx8LIY1HyQ0OPTVo80',
  authDomain: 'app-prenatal.firebaseapp.com',
  databaseURL: 'https://app-prenatal.firebaseio.com',
  projectId: 'app-prenatal',
  storageBucket: 'app-prenatal.appspot.com',
  messagingSenderId: '224867413527',
  appId: '1:224867413527:web:737ff40bf31d1c248ff652',
  measurementId: 'G-55ZEHY8BBT',
};

export default firebaseConfig;
